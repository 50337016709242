
<template>
  <div v-resize="onResize">
    <v-row>
      <v-col cols="6" class="pr-1 pb-0">
        <codemirror
          class="pb-0"
          v-model="score"
          :options="this.cmOptions"
        ></codemirror>
      </v-col>
      <v-col cols="6" class="pl-1 pb-0">
        <v-sheet outlined>
          <span v-show="false">
            {{ this.abcScore }}
          </span>
          <div id="main-midi"></div>
          <div class="sheetImg">
            <div id="engraving"></div>
          </div>
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>


<script>
/* eslint no-unused-vars: 0 */
/* eslint no-undef: 0 */
import CodeMirror from "codemirror";
// import "codemirror/mode/shell/shell.js";
// import "codemirror/mode/abc/abc.js";
import "../codemirror/abcA.js";
// import "../codemirror/monokaiA.js";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/monokai.css";
// import "codemirror/addon/lint/lint.js";
// import "codemirror/addon/lint/lint.css";
import abcjs from "abcjs";
export default {
  props: ["editorHeight", "abcScore"],
  // props: {
  //   editorHeight: { type: Number, default: 100 },
  // },
  data() {
    return {
      cmOptions: {
        tabSize: 4,
        styleActiveLine: true,
        lineNumbers: true,
        line: true,
        mode: "abc",
        lineWrapping: true,
        theme: "monokai",
      },
    };
  },
  updated() {
    this.makeScore();
  },
  computed: {
    editorHightSize: {
      get() {
        return this.editorHeight;
      },
      set() {},
    },
    score: {
      get() {
        var scoreId = this.$route.params.scoreId;
        let scoresStr = localStorage.getItem("scores");
        let scores = JSON.parse(scoresStr);
        for (var i in scores) {
          let score = scores[i];
          if (score.scoreId == scoreId) {
            return score.score;
          }
        }
        return "";
        // return this.$store.getters["score/score"];
      },
      set(newValue) {
        this.$emit("updateScore", newValue);
      },
    },
  },
  created() {},
  mounted() {
    // myMode
    CodeMirror.defineMode("myMode", () => {
      return {
        token(stream, state) {
          if (stream.match("const")) {
            return "style1";
          } else if (stream.match("bbb")) {
            return "style2";
          } else {
            stream.next();
            return null;
          }
        },
      };
    });
    return;
  },
  methods: {
    makeScore() {
      abcjs.renderAbc("paper", this.abcScore);
      var visualObj = abcjs.renderAbc("engraving", this.abcScore, {
        responsive: "resize",
        format: {
          titlefont: '"itim-music,Itim" 24',
          gchordfont: '"itim-music,Itim" 20',
        },
      })[0];
      var synthControl = new abcjs.synth.SynthController();
      synthControl.load("#main-midi", null, {
        displayRestart: true,
        displayPlay: true,
        displayProgress: true,
        displayWarp: true,
      });
      synthControl.setTune(visualObj, false);
    },
    onResize() {
      let displayHeight = window.parent.screen.height;
      let editorHeight = window.innerHeight;

      let newHeight = editorHeight;
      if (editorHeight >= displayHeight) {
        newHeight = displayHeight;
      }
      var codeMirrorElem = document.getElementsByClassName("CodeMirror");
      codeMirrorElem[0].style.height = newHeight - 75 + "px";

      var elem = document.getElementsByClassName("sheetImg");
      elem[0].style.height = newHeight - (75 + 26) + "px";
    },
  },
};
</script>

<style scoped>
.sheetImg {
  overflow-y: scroll;
}

.CodeMirror.CodeMirror-scroll {
  margin-bottom: 0px;
  padding-bottom: 0px !important;
}
</style>