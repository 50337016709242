<template>
  <div v-resize="onResize" class="pa-0">
    <v-container class="pa-0" fluid>
      <span v-show="false">
        {{ this.abcScore }}
        {{ score }}
      </span>
      <v-card flat>
        <div id="main-midi"></div>
        <v-tabs
          v-model="tabs"
          height="30"
          fixed-tabs
          background-color="transparent"
          color="#c42983"
        >
          <v-tab href="#editor"> Editor </v-tab>
          <v-tab href="#score"> Score </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabs">
          <v-tab-item eager value="editor">
            <v-sheet>
              <codemirror
                ref="codeMirrorRef"
                class="pb-0 keypad"
                v-model="score"
                :options="this.cmOptions"
              ></codemirror>
            </v-sheet>
          </v-tab-item>
          <v-tab-item eager value="score">
            <v-sheet>
              <div class="sheetImg">
                <div id="engraving"></div>
              </div>
            </v-sheet>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
      <v-card
        style="position: fixed; bottom: 0px; touch-action: manipulation"
        :min-width="this.$vuetify.breakpoint.width"
        class="pa-2"
        :max-height="displayHeight * 0.42"
        id="allKeyboard"
      >
        <v-row class="pa-2" justify="center">
          <v-col cols="12" class="pa-0">
            <v-row no-gutters>
              <div class="text-caption" style="color: #b9b8b7">
                ABC Keyboard:
              </div>
              <v-col cols="3">
                <v-switch
                  class="pa-0 ma-0"
                  dense
                  hide-details
                  color="secondary"
                  v-model="customKeyboard"
                ></v-switch>
              </v-col>
              <v-spacer />
              <v-btn
                class="px-0"
                @click="goCharLeft()"
                small
                text
                icon
                color="accent"
              >
                <v-icon dencse small>mdi-arrow-expand-left</v-icon>
              </v-btn>
              <v-btn
                class="px-0"
                @click="goCharRight()"
                small
                text
                icon
                color="accent"
              >
                <v-icon dencse small>mdi-arrow-expand-right</v-icon>
              </v-btn>
              <v-btn
                class="px-0"
                @click="goLineUp()"
                small
                text
                icon
                color="accent"
              >
                <v-icon dencse small>mdi-arrow-expand-up</v-icon>
              </v-btn>
              <v-btn
                class="px-0"
                @click="goLineDown()"
                small
                text
                icon
                color="accent"
              >
                <v-icon dencse small>mdi-arrow-expand-down</v-icon>
              </v-btn>
              <v-col cols="1" v-if="customKeyboard">
                <v-btn
                  v-if="showKeyboard"
                  @click="showKeyboard = false"
                  class="px-0"
                  x-small
                  text
                  color="secondary"
                >
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
                <v-btn
                  v-else
                  @click="showKeyboard = true"
                  class="px-0"
                  x-small
                  text
                  color="secondary"
                >
                  <v-icon>mdi-chevron-up</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            v-if="customKeyboard && showKeyboard"
            class="ma-0 pa-0"
            align="left"
          >
            <v-divider></v-divider>
            <v-col class="px-0 mx-0 pt-2" ref="mainKeyboard">
              <v-container fluid clasa="pa-0 ">
                <v-row justify="start">
                  <v-col cols="10">
                    <v-row>
                      <!-- <v-col
                    cols="1"
                    class="text-subtitle-2 grey--text text--darken-1 pa-1"
                  >
                    音
                  </v-col> -->
                      <v-col cols="12">
                        <v-row>
                          <v-col
                            v-for="n in this.key.notes"
                            :key="n.key"
                            style="padding: 0px 1px 2px 0px"
                          >
                            <!--  class="px-0 pt-0 pb-1" -->
                            <v-btn
                              @click="insertNotion(n.value)"
                              min-width="0"
                              outlined
                              tile
                              width="40"
                              height="27"
                              v-text="n.value"
                              color="primary"
                              style="text-transform: none; font-size: 19px"
                            ></v-btn>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            v-for="n in this.key.highNotes"
                            :key="n.key"
                            class="px-0 pt-0 pb-1"
                          >
                            <v-btn
                              @click="insertNotion(n.value)"
                              min-width="0"
                              tile
                              outlined
                              width="40"
                              height="27"
                              v-text="n.value"
                              color="primary"
                              style="
                                text-transform: none;

                                font-size: 19px;
                              "
                            ></v-btn>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            v-for="n in this.key.accidental"
                            :key="n.key"
                            class="px-0 pt-0 pb-1"
                          >
                            <v-btn
                              @click="insertNotion(n.value)"
                              min-width="0"
                              tile
                              outlined
                              width="40"
                              height="27"
                              color="primary"
                              style="text-transform: none; font-size: 19px"
                              v-text="n.value"
                            ></v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="pa-1 pb-2">
                        <v-divider></v-divider>
                      </v-col>
                    </v-row>
                    <!-- <v-col> -->
                    <v-row>
                      <!-- <v-col
                      cols="1"
                      class="text-subtitle-2 grey--text text--darken-1 pa-1"
                    >
                      数
                    </v-col> -->

                      <v-col
                        v-for="n in this.key.numbers"
                        :key="n"
                        style="padding: 0px 1px 2px 0px"
                      >
                        <v-btn
                          @click="insertNotion(n)"
                          min-width="0"
                          tile
                          outlined
                          width="40"
                          height="27"
                          color="primary"
                          style="text-transform: none; font-size: 19px"
                          v-text="n"
                        ></v-btn>
                      </v-col>
                      <v-col
                        v-for="i in 4"
                        :key="'hoge' + i"
                        style="padding: 0px 1px 2px 0px"
                      >
                        <v-btn
                          min-width="0"
                          class="px-0"
                          width="40"
                          height="27"
                          text
                          disabled
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" class="pa-1 pb-2">
                        <v-divider></v-divider>
                      </v-col>
                    </v-row>
                    <v-row>
                      <!-- <v-col
                      cols="1"
                      class="text-subtitle-2 grey--text text--darken-1 pa-1"
                    >
                      記号
                    </v-col> -->

                      <!-- <v-col cols="11"> -->
                      <!-- <v-row> -->
                      <v-col
                        v-for="n in this.key.mainSymbol"
                        :key="n"
                        style="padding: 0px 1px 2px 0px"
                      >
                        <v-btn
                          @click="insertNotion(n)"
                          min-width="0"
                          class="px-0"
                          width="40"
                          height="27"
                          tile
                          outlined
                          v-text="n"
                          color="primary"
                          style="text-transform: none; font-size: 19px"
                        ></v-btn>
                      </v-col>
                      <v-col
                        v-for="(number, index) in 2"
                        :key="'fuga' + index"
                        style="padding: 0px 1px 2px 0px"
                      >
                        <v-btn
                          min-width="0"
                          class="px-0"
                          width="40"
                          height="27"
                          text
                          disabled
                        />
                      </v-col>
                    </v-row>
                  </v-col>

                  <!-- スペースとか -->

                  <v-col class="pa-0 ma-0" cols="1">
                    <v-row>
                      <v-col cols="1" class="pr-0">
                        <v-col cols="3" class="pa-0 ma-0 pb-1">
                          <v-btn
                            @click="backspace()"
                            outlined
                            :height="this.mainKeyboardHeight * 0.25"
                            width="26"
                            color="primary"
                            small
                            style="text-transform: none"
                          >
                            <v-icon> mdi-backspace-outline </v-icon>
                          </v-btn>
                        </v-col>
                        <v-col cols="3" class="pa-0 ma-0 pb-1">
                          <v-btn
                            @click="insertNotion(' ')"
                            outlined
                            :height="this.mainKeyboardHeight * 0.25"
                            width="26"
                            color="primary"
                            small
                            style="text-transform: none"
                            class="pa-0 ma-0"
                          >
                            <v-icon> mdi-keyboard-space </v-icon>
                          </v-btn>
                        </v-col>
                        <v-col cols="6" class="pa-0 ma-0 pb-1">
                          <v-btn
                            @click="insertNotion('\n')"
                            outlined
                            :height="this.mainKeyboardHeight * 0.4"
                            width="26"
                            color="primary"
                            small
                            style="text-transform: none"
                          >
                            <v-icon> mdi-arrow-left-bottom </v-icon>
                          </v-btn>
                        </v-col>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
              <!-- </v-sheet> -->
            </v-col>
            <!-- v-if="this.keyboard" -->
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>


<script>
/* eslint no-unused-vars: 0 */
/* eslint no-undef: 0 */
import CodeMirror from "codemirror";
// import "codemirror/mode/shell/shell.js";
// import "codemirror/mode/abc/abc.js";
import "../../codemirror/abcA.js";
// import "../codemirror/monokaiA.js";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/monokai.css";
// import "codemirror/addon/lint/lint.js";
// import "codemirror/addon/lint/lint.css";
import abcjs from "abcjs";
export default {
  props: ["editorHeight", "abcScore"],
  data() {
    return {
      codemirrorInstance: null,
      displayHeight: 800,
      mainKeyboardHeight: 133,
      allKeyboardHeight: 400,
      tabs: "editor",
      inputScore: "bb",
      showKeyboard: true,
      customKeyboard: true,

      key: {
        notes: [
          { key: "ド", value: "C" },
          { key: "レ", value: "D" },
          { key: "ミ", value: "E" },
          { key: "ファ", value: "F" },
          { key: "ソ", value: "G" },
          { key: "ラ", value: "A" },
          { key: "シ", value: "B" },
        ],
        highNotes: [
          { key: "ド↑", value: "c" },
          { key: "レ↑", value: "d" },
          { key: "ミ↑", value: "e" },
          { key: "ファ↑", value: "f" },
          { key: "ソ↑", value: "g" },
          { key: "ラ↑", value: "a" },
          { key: "シ↑", value: "b" },
        ],
        accidental: [
          { key: "mdi-music-rest-quarter", value: "z" },
          { key: "mdi-cat", value: "Z" },
          { key: "mdi-arrow-down-bold", value: "," },
          { key: "mdi-arrow-up-bold", value: "'" },
          { key: "mdi-music-accidental-sharp", value: "^" },
          { key: "mdi-music-accidental-flat", value: "_" },
          { key: "mdi-music-accidental-natural", value: "=" },
        ],
        numbers: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"],
        mainSymbol: [
          "[",
          "]",
          "(",
          ")",
          "{",
          "}",
          "/",
          "|",
          ":",
          "-",
          ".",
          "~",
        ],
        Symbol: ["H", "L", "T", "M", "O", "P", "S", "u", "v"],
      },
      cmOptions: {
        tabSize: 4,
        styleActiveLine: true,
        lineNumbers: true,
        line: true,
        mode: "abc",
        lineWrapping: true,
        theme: "monokai",
        inputStyle: "textarea",
        cursorBlinkRate: 500,
        readOnly: true,
        viewportMargin: 200,
        // closeOnBlur: false,
        // readOnly: true,
      },
    };
  },
  watch: {
    showKeyboard: function (newVal, oldVal) {
      this.onResize();
    },
    customKeyboard: function (newVal, oldVal) {
      this.onResize();
      this.cmOptions.readOnly = newVal;
      setTimeout(() => {
        if (!newVal) {
          //customKeyboardが有効じゃない場合はsetしない
          return;
        }
        this.cursorPosition = this.codemirrorInstance.getCursor();
        this.codemirrorInstance.focus();
        this.codemirrorInstance.setCursor({
          line: this.cursorPosition.line,
          ch: this.cursorPosition.ch,
        });
      }, 0);
    },
  },
  updated() {
    this.makeScore();
  },
  computed: {
    editorHightSize: {
      get() {
        return this.editorHeight;
      },
      set() {},
    },
    score: {
      get() {
        return this.inputScore;
      },
      set(newValue) {
        this.inputScore = newValue;
        this.$emit("updateScore", newValue);
      },
    },
  },
  created() {},
  mounted() {
    this.displayHeight = this.$vuetify.breakpoint.height;
    this.mainKeyboardHeight = this.$refs.mainKeyboard.clientHeight;
    this.allKeyboardHeight =
      document.getElementById("allKeyboard").clientHeight;

    var scoreId = this.$route.params.scoreId;
    let scoresStr = localStorage.getItem("scores");
    let scores = JSON.parse(scoresStr);
    for (var i in scores) {
      let score = scores[i];
      if (score.scoreId == scoreId) {
        this.inputScore = score.score;
        break;
      }
    }

    this.makeScore();
    // myMode
    CodeMirror.defineMode("myMode", () => {
      return {
        token(stream, state) {
          if (stream.match("const")) {
            return "style1";
          } else if (stream.match("bbb")) {
            return "style2";
          } else {
            stream.next();
            return null;
          }
        },
      };
    });

    this.codemirrorInstance = this.$refs.codeMirrorRef.codemirror;
    // 行末に移動
    setTimeout(() => {
      this.codemirrorInstance.focus();
      this.codemirrorInstance.execCommand("goDocEnd");
    }, 0);

    //画面タッチしたときに発火
    this.$refs.codeMirrorRef.codemirror.on("touchstart", this.onkeyboard);
    //cursor変更時に発火
    this.$refs.codeMirrorRef.codemirror.on("cursorActivity", this.movoCursor);
    //フォーカスされていないとき発火
    this.$refs.codeMirrorRef.codemirror.on("blur", this.blur);

    return;
  },
  methods: {
    onkeyboard() {
      if (!this.showKeyboard) {
        this.showKeyboard = true;
      }
    },
    movoCursor() {
      // 動いたcursor位置をセットする
      this.cursorPosition = this.codemirrorInstance.getCursor();
    },
    blur() {
      //cursor外れたときに最後のcursor位置取得してsetしなおす
      setTimeout(() => {
        if (!this.customKeyboard) {
          //customKeyboardが有効じゃない場合はsetしない
          return;
        }
        this.cursorPosition = this.codemirrorInstance.getCursor();
        this.codemirrorInstance.focus();
        this.codemirrorInstance.setCursor({
          line: this.cursorPosition.line,
          ch: this.cursorPosition.ch,
        });
      }, 0);
    },
    insertNotion(value) {
      this.codemirrorInstance.replaceRange(value, {
        line: this.cursorPosition.line,
        ch: this.cursorPosition.ch,
      });
    },
    backspace() {
      this.codemirrorInstance.execCommand("delCharBefore");
    },
    goCharLeft() {
      this.codemirrorInstance.execCommand("goCharLeft");
    },
    goCharRight() {
      this.codemirrorInstance.execCommand("goCharRight");
    },
    goLineDown() {
      this.codemirrorInstance.execCommand("goLineDown");
    },
    goLineUp() {
      this.codemirrorInstance.execCommand("goLineUp");
    },
    makeScore() {
      abcjs.renderAbc("paper", this.abcScore);
      var visualObj = abcjs.renderAbc("engraving", this.abcScore, {
        responsive: "resize",
        format: {
          titlefont: '"itim-music,Itim" 24',
          gchordfont: '"itim-music,Itim" 20',
        },
      })[0];

      var synthControl = new abcjs.synth.SynthController();
      synthControl.load("#main-midi", null, {
        displayRestart: true,
        displayPlay: true,
        displayProgress: true,
        displayWarp: true,
      });
      synthControl.setTune(visualObj, false);
    },
    onResize() {
      setTimeout(() => {
        const allKeyboardHeight =
          document.getElementById("allKeyboard").clientHeight;
        let displayHeight = window.parent.screen.height;
        let editorHeight = window.innerHeight;
        let newHeight = editorHeight;
        if (editorHeight >= displayHeight) {
          newHeight = displayHeight;
        }
        var codeMirrorElem = document.getElementsByClassName("CodeMirror");
        codeMirrorElem[0].style.height =
          newHeight - (170 + allKeyboardHeight + 25) + "px";

        var elem = document.getElementsByClassName("sheetImg");
        elem[0].style.height =
          newHeight - (170 + allKeyboardHeight + 25) + "px";
      }, 0);
    },
  },
};
</script>

<style scoped>
/* .CodeMirror {
  border: 1px solid #eee;
  height: auto;
} */

/* .CodeMirror {
  border: 1px solid #eee;
  height: auto;
}
.CodeMirror-scroll {
  overflow-y: hidden;
  overflow-x: auto;
} */
.sheetImg {
  overflow-y: scroll;
}

.CodeMirror.CodeMirror-scroll {
  margin-bottom: 0px;
  padding-bottom: 0px !important;
}
.CodeMirror-readonly .CodeMirror-cursor {
  display: none !important;
}
</style>