<template>
  <div class="text-center">
    <v-dialog fullscreen persistent v-model="dialog" width="700">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Setting
          <v-spacer></v-spacer>
          <v-btn large icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-col cols="12">
            <!-- <BPM
              :tempo="this.tempo"
              @decrement="decrement"
              @increment="increment"
              @update-tempo="updateTempo"
            /> -->
            <!-- @update-tempo="updateTempo" -->
          </v-col>

          <v-divider></v-divider>

          <v-row dense>
            <!-- ラジオボタン -->
            <v-radio-group dense mandatory hide-details="true" v-model="type">
              <v-radio dense label="major" value="major"></v-radio>
              <v-radio dense label="minor" value="minor"></v-radio>
            </v-radio-group>

            <!-- 調を選ぶ -->
            <v-col cols="3" class="mt-6 ml-4">
              <v-select
                v-model="key"
                :items="selectKey"
                label="調"
                :item-text="selectKey.text"
                :item-value="selectKey.key"
                return-object
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2">
              <v-text-field
                :rules="rhythm_rules"
                v-model="topNumber"
                label="topNumber"
                hint="拍子/分子"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field
                :rules="rhythm_rules"
                v-model="bottomNumber"
                hint="音符/分母"
                label="bottomNumber"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-col cols="3">
            <v-text-field
              disabled
              v-model="standard_note"
              label="1音の基準の長さ"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-btn color="#da003a" outlined @click="dialog = false"> OK </v-btn>
          </v-col>
        </v-card-text>
        <!-- <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="success" @click.stop="dialog = false"> OK </v-btn>
        </v-card-actions> -->
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable*/
import BPM from "../components/BPM.vue";
export default {
  props: ["settingDialog", "scoreObj"],
  components: { BPM },
  data() {
    return {
      scoreId: "",
      // scoreObj: {},
      standard_note: "1/4",
      keys: [
        {
          type: "major",
          subKeys: [
            { text: "C", key: "C" },
            { text: "G", key: "G" },
            { text: "D", key: "D" },
            { text: "A", key: "A" },
            { text: "E", key: "E" },
            { text: "B", key: "B" },
            { text: "F#", key: "F#" },
            { text: "C#", key: "C#" },
            { text: "F", key: "F" },
            { text: "B♭", key: "Bb" },
            { text: "E♭", key: "Eb" },
            { text: "A♭", key: "Ab" },
            { text: "D♭", key: "Db" },
            { text: "G♭", key: "Gb" },
            { text: "C♭", key: "Cb" },
          ],
        },
        {
          type: "minor",
          subKeys: [
            { text: "Am", key: "Am" },
            { text: "Em", key: "Em" },
            { text: "Bm", key: "Bm" },
            { text: "F#m", key: "F#m" },
            { text: "C#m", key: "C#m" },
            { text: "G#m", key: "G#m" },
            { text: "A#m", key: "A#" },
            { text: "Dm", key: "Dm" },
            { text: "Gm", key: "Gm" },
            { text: "Cm", key: "Cm" },
            { text: "Fm", key: "Fm" },
            { text: "Bm", key: "Bbm" },
            { text: "E♭", key: "Ebm" },
            { text: "A♭", key: "Abm" },
          ],
        },
      ],
      rhythm_rules: [
        (v) => !!v || "",
        // (V) => "/[1-9]/" || "数値で入力してください",
      ],
    };
  },
  mounted() {},
  computed: {
    tempo: {
      get() {
        return this.scoreObj.tempo;
        // return this.$store.getters["score/tempo"];
      },
      set(newValue) {
        this.$emit("updateTempo", newValue);
      },
    },
    type: {
      get() {
        return this.scoreObj.type;
        // return this.$store.getters["score/type"];
      },
      set(newValue) {
        this.$emit("updateType", newValue);
      },
    },
    key: {
      get() {
        return this.scoreObj.key;
        // return this.$store.getters["score/key"];
      },
      set(newValue) {
        this.$emit("updateKey", newValue);
      },
    },
    selectKey: {
      get() {
        if (this.type == "major") {
          return this.keys[0].subKeys;
        } else {
          return this.keys[1].subKeys;
        }
      },
      set(newValue) {},
    },
    topNumber: {
      get() {
        return this.scoreObj.topNumber;
        // return this.$store.getters["score/topNumber"];
      },
      set(newValue) {
        this.$emit("updateTopNumber", newValue);
      },
    },
    bottomNumber: {
      get() {
        return this.scoreObj.bottomNumber;
        // return this.$store.getters["score/bottomNumber"];
      },
      set(newValue) {
        this.$emit("updateBottomNumber", newValue);
      },
    },
    dialog: {
      get() {
        return this.settingDialog.dialog;
      },
      set() {
        this.$emit("closeDialog");
      },
    },
  },
  methods: {
    increment() {
      this.tempo++;
    },
    decrement() {
      this.tempo--;
    },
    updateTempo(tempo) {
      this.tempo = tempo;
    },
  },
};
</script>