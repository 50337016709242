<template>
  <div>
    <v-app-bar v-if="!isMobile" height="30" color="white" flat outlined>
      <v-col cols="5" xl="5" lg="4" mg="4">
        <v-text-field
          class="mt-2"
          color="#da003a"
          v-model="title"
          prefix="Title:"
        >
        </v-text-field>
      </v-col>

      <v-col no-gutters cols="3" class="mb-4 pb-0">
        <BPM
          :tempo="this.tempo"
          @decrement="decrement"
          @increment="increment"
          @update-tempo="updateTempo"
        />
      </v-col>

      <v-spacer />

      <v-btn color="#c42983" plain @click="this.showDialog"> SETTING </v-btn>
      <v-btn plain color="#c42983" @click="download" id="midi-link">
        MIDI DOWNLOAD
        <a href="" />
      </v-btn>

      <v-btn color="#c42983" plain @click="screen"> get score image </v-btn>
    </v-app-bar>

    <v-app-bar v-if="isMobile" height="90" color="white" flat outlined>
      <v-row justify="center" no-gutters>
        <v-col align="right" no-gutters cols="12">
          <v-btn x-small color="#c42983" plain @click="this.showDialog">
            SETTING
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-text-field
            color="#da003a"
            v-model="title"
            prefix="Title:"
            hide-details
          >
          </v-text-field>
        </v-col>
        <v-spacer />

        <v-col no-gutters cols="12" class="pt-2">
          <BPM
            :tempo="this.tempo"
            @decrement="decrement"
            @increment="increment"
            @update-tempo="updateTempo"
          />
        </v-col>

        <!-- <v-col align="left" no-gutters cols="12" class="pb-3 pt-0 mt-0">
          <v-btn
            class="pl-0"
            x-small
            color="#c42983"
            plain
            @click="this.showDialog"
          >
            SETTING
          </v-btn>
          <v-btn x-small plain color="#c42983" @click="download" id="midi-link">
            MIDI DOWNLOAD
            <a href="" />
          </v-btn>

          <v-btn x-small color="#c42983" plain @click="screen">
            get score image
          </v-btn>
        </v-col> -->
      </v-row>
    </v-app-bar>
    <scoreSetting
      :settingDialog="this.settingDialog"
      :scoreObj="this.scoreObj"
      @closeDialog="closeDialog"
      @updateTopNumber="updateTopNumber"
      @updateBottomNumber="updateBottomNumber"
      @updateTempo="updateTempo"
      @updateType="updateType"
      @updateKey="updateKey"
    />
    <!-- <div class="hidden-sm-and-down"> -->
    <Editor
      v-if="!isMobile"
      :editorHeight="this.editorHeight"
      :abcScore="this.message"
      @updateScore="updateScore"
    >
    </Editor>
    <!-- </div> -->
    <!-- <div class="hidden-md-and-up"> -->
    <EditorMobile
      v-if="isMobile"
      :editorHeight="this.editorHeight"
      :abcScore="this.message"
      @updateScore="updateScore"
    />
    <!-- </div> -->

    <Keybord
      :keybordDialog="this.keybordDialog"
      @closeKeybordDialog="closeKeybordDialog"
    />
  </div>
</template>


<script>
/* eslint-disable*/
import abcjs from "abcjs";
import "abcjs/abcjs-audio.css";
import Editor from "../components/Editor.vue";
import html2canvas from "html2canvas";
import callApi from "../js/callApi";
import scoreSetting from "../components/ScoreSetting.vue";
import Keybord from "../components/Keyboard.vue";
import BPM from "../components/BPM.vue";
import EditorMobile from "../components/mobile/EditorMobile.vue";

export default {
  components: { scoreSetting, abcjs, Editor, Keybord, BPM, EditorMobile },
  data() {
    return {
      scoreId: null,
      scoreObj: { key: { text: "C", key: "C" } },
      keybordDialog: { dialog: false },
      settingDialog: { dialog: false },
      abc_score: "",
      type: "",
      editorHeight: 100,
      header: 100,
      windowSize: {
        x: 0,
        y: 0,
      },
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.getLocalStorage();
      next();
    });
  },
  mounted() {},

  computed: {
    isMobile() {
      const mobileBreakpoints = ["xs", "sm"];
      return mobileBreakpoints.some((e) => {
        return this.$vuetify.breakpoint.name === e;
      });
    },
    message: {
      get() {
        let title = "\nT:" + this.title;
        let rhythm = "\nM:" + this.topNumber + "/" + this.bottomNumber;
        let standard_note = "\nL:" + this.standard_note;
        let key = "\nK:" + this.key.key;
        let bpm = "\nQ:" + this.tempo;
        this.abc_score =
          "X:1" +
          title +
          rhythm +
          standard_note +
          key +
          bpm +
          "\n" +
          this.score;
        return this.abc_score;
      },
      set(newValue) {
        // TODO
      },
    },
    getScoreId: {
      get() {
        return this.scoreObj.scoreId;
        // return this.$store.getters["score/scoreId"];
      },
      set() {},
    },
    title: {
      get() {
        return this.scoreObj.title;
        // return this.$store.getters["score/title"];
      },
      set(newValue) {
        this.scoreObj.title = newValue;
        let scoresStr = localStorage.getItem("scores");
        let scores = JSON.parse(scoresStr);
        for (let i in scores) {
          let score = scores[i];
          if (this.scoreId == score.scoreId) {
            score.title = newValue;
          }
        }
        localStorage.setItem("scores", JSON.stringify(scores));
        this.getLocalStorage();
        // this.$store.dispatch("score/setTitle", newValue);
      },
    },
    score: {
      get() {
        // var res = this.$store.getters["scoreListLocal/score"](
        //   "hoho",
        //   "scoreId"
        // );
        return this.scoreObj.score;
      },
      // set(newValue) {
      //   this.$store.dispatch("score/setScore", newValue);
      // },
    },
    key: {
      get() {
        return this.scoreObj.key;
        // return this.$store.getters["score/key"];
      },
    },
    tempo: {
      get() {
        return this.scoreObj.tempo;
        // return this.$store.getters["score/tempo"];
      },
    },
    topNumber: {
      get() {
        return this.scoreObj.topNumber;
        // return this.$store.getters["score/topNumber"];
      },
    },
    bottomNumber: {
      get() {
        return this.scoreObj.bottomNumber;
        // return this.$store.getters["score/bottomNumber"];
      },
    },
    standard_note: {
      get() {
        return this.scoreObj.standard_note;
      },
    },
  },
  methods: {
    getLocalStorage() {
      this.scoreId = this.$route.params.scoreId;
      let scoresStr = localStorage.getItem("scores");
      let scores = JSON.parse(scoresStr);
      for (var i in scores) {
        let score = scores[i];
        if (score.scoreId == this.scoreId) {
          this.scoreObj = score;
          break;
        }
      }
    },
    download() {
      const score = this.abc_score;
      this.abc_score += "\n";
      var midi = abcjs.synth.getMidiFile(score, {
        // chordsOff: true,
        midiOutputType: "encoded",
      });
      const aTag = document.createElement("a");
      aTag.download =
        this.title +
        "_" +
        $DateTime.now().toFormat("yyyyMMddHHmmss").toString() +
        ".midi";
      aTag.href = midi;
      aTag.click();
      URL.revokeObjectURL(aTag.href);
    },
    screen() {
      html2canvas(document.querySelector("#engraving")).then((canvas) => {
        const link = document.createElement("a");
        link.href = canvas.toDataURL();
        var name =
          this.title +
          "_" +
          $DateTime.now().toFormat("yyyyMMddHHmmss").toString() +
          ".png";
        link.download = name;
        link.click();
      });
    },
    updateScore(newValue) {
      this.scoreObj.score = newValue;
      let scoresStr = localStorage.getItem("scores");
      let scores = JSON.parse(scoresStr);
      for (let i in scores) {
        let score = scores[i];
        if (this.scoreId == score.scoreId) {
          score.score = newValue;
        }
      }
      localStorage.setItem("scores", JSON.stringify(scores));
    },
    showDialog() {
      this.settingDialog.dialog = true;
    },
    closeDialog() {
      this.settingDialog.dialog = false;
    },
    showKeybordDialog() {
      this.keybordDialog.dialog = true;
    },
    closeKeybordDialog() {
      this.keybordDialog.dialog = false;
    },
    updateTopNumber(newValue) {
      this.scoreObj.topNumber = newValue;
      let scoresStr = localStorage.getItem("scores");
      let scores = JSON.parse(scoresStr);
      for (let i in scores) {
        let score = scores[i];
        if (this.scoreId == score.scoreId) {
          score.topNumber = newValue;
        }
      }
      localStorage.setItem("scores", JSON.stringify(scores));
      this.getLocalStorage();
      // this.$store.dispatch("score/setTopNumber", newValue);
    },
    updateBottomNumber(newValue) {
      this.scoreObj.bottomNumber = newValue;
      let scoresStr = localStorage.getItem("scores");
      let scores = JSON.parse(scoresStr);
      for (let i in scores) {
        let score = scores[i];
        if (this.scoreId == score.scoreId) {
          score.bottomNumber = newValue;
        }
      }
      localStorage.setItem("scores", JSON.stringify(scores));
      this.getLocalStorage();
      // this.$store.dispatch("score/setBottomNumber", newValue);
    },
    updateTempo(newValue) {
      this.scoreObj.tempo = newValue;
      let scoresStr = localStorage.getItem("scores");
      let scores = JSON.parse(scoresStr);
      for (let i in scores) {
        let score = scores[i];
        if (this.scoreId == score.scoreId) {
          score.tempo = newValue;
        }
      }
      localStorage.setItem("scores", JSON.stringify(scores));
      this.getLocalStorage();
      // this.$store.dispatch("score/setTempo", newValue);
    },
    updateType(newValue) {
      this.scoreObj.type = newValue;
      let scoresStr = localStorage.getItem("scores");
      let scores = JSON.parse(scoresStr);
      for (let i in scores) {
        let score = scores[i];
        if (this.scoreId == score.scoreId) {
          score.type = newValue;
        }
      }
      localStorage.setItem("scores", JSON.stringify(scores));
      this.getLocalStorage();
      // this.$store.dispatch("score/setType", newValue);
    },
    updateKey(newValue) {
      this.scoreObj.key = newValue;
      let scoresStr = localStorage.getItem("scores");
      let scores = JSON.parse(scoresStr);
      for (let i in scores) {
        let score = scores[i];
        if (this.scoreId == score.scoreId) {
          score.key = newValue;
        }
      }
      localStorage.setItem("scores", JSON.stringify(scores));
      this.getLocalStorage();
      // this.$store.dispatch("score/setKey", newValue.key);
    },

    increment() {
      // this.tempo++;
      this.scoreObj.tempo++;
      let scoresStr = localStorage.getItem("scores");
      let scores = JSON.parse(scoresStr);
      for (let i in scores) {
        let score = scores[i];
        if (this.scoreId == score.scoreId) {
          score.tempo = this.scoreObj.tempo;
        }
      }
      localStorage.setItem("scores", JSON.stringify(scores));
      this.getLocalStorage();
    },
    decrement() {
      // this.tempo--;
      this.scoreObj.tempo--;
      let scoresStr = localStorage.getItem("scores");
      let scores = JSON.parse(scoresStr);
      for (let i in scores) {
        let score = scores[i];
        if (this.scoreId == score.scoreId) {
          score.tempo = this.scoreObj.tempo;
        }
      }
      localStorage.setItem("scores", JSON.stringify(scores));
    },
  },
};
</script>

