<template>
  <div class="text-center">
    <v-sheet
      id="keybord"
      class="mx-9 default"
      v-if="dialog"
      color="white"
      elevation="18"
      rounded="xl"
      :width="this.$vuetify.breakpoint.width * 0.23"
      :height="this.$vuetify.breakpoint.height * 0.9"
      :style="`position: fixed; z-index: 9999; top: 50px;`"
    >
      <br />
      <div class="mx-6">
        <v-row justify="end">
          <v-btn icon v-if="keybordJustify" @click="moveKeybord">
            <v-icon>mdi-arrow-left-thin</v-icon></v-btn
          >
          <v-btn icon v-else @click="moveKeybord"
            ><v-icon>mdi-arrow-right-thin</v-icon></v-btn
          >
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon></v-btn
          >
        </v-row>

        <v-row>
          <p>高さ</p>
          <v-col align="start" cols="12" class="pt-0 mt-0">
            <v-btn small outlined>,</v-btn>
            <v-btn small outlined>'</v-btn>
          </v-col>
          <p>音階</p>
          <v-col align="start" cols="12" class="pt-0 mt-0">
            <v-btn small outlined>C</v-btn>
            <v-btn small outlined>D</v-btn>
            <v-btn small outlined>E</v-btn>
            <v-btn small outlined>F</v-btn>
            <v-btn small outlined>G</v-btn>
            <v-btn small outlined>A</v-btn>
            <v-btn small outlined>B</v-btn>
            <v-btn style="text-transform: none" small outlined>c</v-btn>
            <v-btn style="text-transform: none" small outlined>d</v-btn>
            <v-btn style="text-transform: none" small outlined>e</v-btn>
            <v-btn style="text-transform: none" small outlined>f</v-btn>
            <v-btn style="text-transform: none" small outlined>g</v-btn>
            <v-btn style="text-transform: none" small outlined>a</v-btn>
            <v-btn style="text-transform: none" small outlined>b</v-btn>
          </v-col>
          <p>数字</p>
          <v-col align="start" cols="12" class="pt-0 mt-0">
            <v-btn small outlined>1</v-btn>
            <v-btn small outlined>2</v-btn>
            <v-btn small outlined>3</v-btn>
            <v-btn small outlined>4</v-btn>
            <v-btn small outlined>5</v-btn>
            <v-btn small outlined>6</v-btn>
            <v-btn small outlined>7</v-btn>
            <v-btn small outlined>8</v-btn>
            <v-btn small outlined>9</v-btn>
            <v-btn small outlined>10</v-btn>
          </v-col>
          <p>記号</p>
          <v-col align="start" cols="12" class="pt-0 mt-0">
            <v-btn small outlined>|</v-btn>
            <v-btn small outlined>:</v-btn>
            <v-btn small outlined>[</v-btn>
            <v-btn small outlined>]</v-btn>
            <v-btn small outlined>(</v-btn>
            <v-btn small outlined>)</v-btn>
            <v-btn small outlined>-</v-btn>
            <v-btn small outlined>.</v-btn>
            <v-btn small outlined>~</v-btn>
            <v-btn small outlined>H</v-btn>
            <v-btn small outlined>L </v-btn>
            <v-btn small outlined>10</v-btn>
          </v-col>
        </v-row>

        {{ this.$vuetify.breakpoint.height }}
        a
      </div>
    </v-sheet>
  </div>
</template>

<script>
export default {
  props: ["keybordDialog"],
  data() {
    return {
      keybordJustify: true,
      x: 300,
    };
  },
  computed: {
    dialog: {
      get() {
        return this.keybordDialog.dialog;
      },
      set() {
        this.$emit("closeKeybordDialog");
      },
    },
  },
  methods: {
    moveKeybord() {
      // 右にあるときはtrue
      var element = document.getElementById("keybord");
      if (this.keybordJustify) {
        element.classList.remove("default");
        element.classList.remove("moveRight");
        element.classList.add("moveLeft");
        this.keybordJustify = false;
      } else {
        element.classList.remove("default");
        element.classList.remove("moveLeft");
        element.classList.add("moveRight");
        this.keybordJustify = true;
      }
    },
  },
};
</script>

<style scoped>
.default {
  position: absolute;
  right: 0px;
}
.moveRight {
  position: absolute;
  animation: moveRight 1s forwards;
  right: 0px;
}
.moveLeft {
  position: absolute;
  animation: moveLeft 1s forwards;
}

@keyframes moveLeft {
  /* 左に行く */
  0% {
    /* margin-right: 200%; */
    transform: translateX(1500px);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes moveRight {
  /* 右に行く */
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0%);
  }
}
</style>
