<template>
  <v-row no-gutters>
    <v-col cols="1" xl="1" lg="2" md="2">
      <span class="subheading font-weight-light" v-text="this.bpm"></span>
    </v-col>
    <v-col cols="10" xl="10" lg="10" md="10" class="ma-0 pa-0">
      <v-slider
        dense
        hide-details="false"
        v-model="bpm"
        :color="color"
        track-color="grey"
        always-dirty
        min="1"
        max="300"
      >
        <template v-slot:prepend>
          <v-icon :color="color" @click="decrement"> mdi-minus </v-icon>
        </template>

        <template v-slot:append>
          <v-icon :color="color" @click="increment"> mdi-plus </v-icon>
        </template>
      </v-slider>
    </v-col>
    <!-- <v-col cols="1">
      <v-btn x-small text :color="color" dark depressed fab @click="toggle">
      </v-btn>
    </v-col> -->
    <!-- </v-row> -->
    <!-- </v-col> -->
  </v-row>
</template>
<script>
export default {
  props: ["tempo"],
  // props: {
  //   tempo: {
  //     type: Number,
  //     default: 120,
  //   },
  // },
  data: () => ({
    // bpm: 40,
    interval: null,
    isPlaying: false,
  }),
  watch: {
    // bpm: function (newVal, oldVal) {
    //   this.$emit("update-tempo", oldVal);
    // },
  },
  computed: {
    bpm: {
      get() {
        return this.tempo;
      },
      set(newValue) {
        this.$emit("update-tempo", newValue);
      },
    },
    color() {
      if (this.bpm < 100) return "indigo";
      if (this.bpm < 125) return "teal";
      if (this.bpm < 140) return "green";
      if (this.bpm < 175) return "orange";
      return "red";
    },
    animationDuration() {
      return `${60 / this.bpm}s`;
    },
  },

  methods: {
    decrement() {
      this.$emit("decrement");
    },
    increment() {
      this.$emit("increment");
    },
    // toggle() {
    //   this.isPlaying = !this.isPlaying;
    // },
  },
};
</script>

<style scoped>
@keyframes metronome-example {
  from {
    transform: scale(0.5);
  }

  to {
    transform: scale(1);
  }
}

.v-avatar--metronome {
  animation-name: metronome-example;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
</style>

